jQuery(function(){
	var headerHeight = 40; // ヘッダーの高さ
	if (window.matchMedia('(min-width: 769px)').matches) {
		headerHeight = 130;
	}
	jQuery('a[href^="#"]').click(function(){
		var speed = 500;
		var href= jQuery(this).attr("href");
		var target = jQuery(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top - headerHeight;
		jQuery("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});

});

jQuery(window).on('load', function() {
	var headerHeight = 40; // ヘッダーの高さ
	if (window.matchMedia('(min-width: 769px)').matches) {
		headerHeight = 130;
	}
	if (document.URL.match('#')) {
		var str = location.href;
		var cut_str = "#";
		var index = str.indexOf(cut_str);
		var href = str.slice(index);
		var target = href;
		var position = jQuery(target).offset().top - headerHeight;
		jQuery("html, body").scrollTop(position);
		return false;
	}
});