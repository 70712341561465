jQuery(function () {
  jQuery(window).scroll(function () {
    const windowHeight = jQuery(window).height();
    const scroll = jQuery(window).scrollTop();

    jQuery('.js-in').each(function () {
      const targetPosition = jQuery(this).offset().top;
      if (scroll > targetPosition - windowHeight + 50) {
        jQuery(this).addClass("is-active");
      }
    });
  });
});