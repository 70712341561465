$(document).ready(function() {
  $('#toggle').on('click', function(){
    $(this).toggleClass('is-active');
    $('#sp-nav').toggleClass('is-active');
  });

  $('#sp-nav a').on('click', function(){
    $('#toggle').removeClass('is-active');
    $('#sp-nav').removeClass('is-active');
  });
});